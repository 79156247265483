<template>
  <div>
    <v-card
    class="cardEmp"
    raised
  >
    <!-- <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="list-item, list-item-three-line, list-item-three-line, list-item"
      elevation="2"
      height="300"
    ></v-skeleton-loader> -->
    <v-list shapped>
      <v-subheader class="titleCardEmp">Empresas pendentes provação</v-subheader>
      <v-divider></v-divider>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-4"></v-divider>  
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
          <div>Atualizado há 2 minutos atrás.</div>
        </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
  </div>
</template>

<script>
export default {
  name: 'cardEmpRegister',
  data: () => ({
    items: [
      { text: 'QUIMIL IND', icon: 'mdi-account-check' },
      { text: 'VICUNHA TEXTIL', icon: 'mdi-account-check' },
      { text: 'NORSA INDUSTRIA', icon: 'mdi-account-check' },
    ],
  }),
}
</script>

<style scoped>
  .cardEmp {
    position: relative;
    max-width: 300px;
    height: auto;
    left: 100px;
    top: 100px;
  }
  .titleCardEmp {
    font-size: 19px;
    font-weight: 800;
  }
</style>