export const CreateObjectFilters = (filters, entities) => ({
    createFilters() {
        for (var prop in entities) {
            if (!entities.hasOwnProperty(prop)) continue;
            if (entities[prop]) {
                if (entities[prop] !== "Todas" && entities[prop] !== "Todos") {
                    filters[prop] = entities[prop]
                }
            }
        }
        console.log('factory', filters);
        return filters;
    }
});