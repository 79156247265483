<template>
  <v-card raised class="cardGrid">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Pesquisar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-toolbar flat v-if="showHeaderTable">
      <v-toolbar-title class="titleTable" v-if="this.typeReports == 'Pauta Reunião'">{{informationHeader}}</v-toolbar-title>
      <div class="total" v-if="!showButtonsTableProtocol">
        <v-text-field class="none"></v-text-field><v-text-field class="none">
        </v-text-field><v-text-field class="none"></v-text-field>
      </div>
      <div class="total" v-if="showButtonsTableProtocol">
        <v-text-field 
          v-model="totalEmpresas"
          class="totalEmp"
          readonly
          outlined
          filled
          dense
          :loading="loading"
          label="Total de Empresas"
        ></v-text-field>
        <v-text-field
          v-model="totalInvestimentos"
          class="totalInvest"
          readonly
          outlined
          filled
          dense
          :loading="loading"
          label="Total de Investimentos"
        ></v-text-field>
        <v-text-field
          v-model="totalEmpregos"
          readonly
          outlined
          filled
          dense
          :loading="loading"
          label="Total de Empregos"
        ></v-text-field>
      </div>
      <div class="btnExport" v-if="showExportExcel">
        <ExportExcel  
          :data="dataXls"
          :columns="columnsXls"
          :filename="fileNameExcel"
          :sheetname="informationHeader">
        </ExportExcel>
      </div>
      <div class="btnExport"  v-if="showExportPDF">
        <v-btn :loading="loading" v-on:click="downloadPDF" class="ma-2" tile outlined color="success">
          <v-icon left>mdi-download</v-icon> Baixar PDF
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar> 
    <v-data-table
        :loading="loading"
        no-data-text="Nenhuma informação disponivel"
        no-results-text="Nenhuma informação disponivel"
        dense
        :headers="headers"
        :items="data"
        :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory.js';
const ReuniaoRepository = Repository.get("reuniao");
import ExportExcel from '@/components/ExportExcel.vue';

export default {
  name: 'dataTable',
  props: {
    dataFilters: '',
    typeReports: '',
  },
  components: {
    ExportExcel,
  },
  data () {
    return {
      search: '',
      headers: [],
      data: [],
      loading: false,
      showHeaderTable: false,
      informationHeader: '',
      columnsXls : [],
      dataXls : [],
      totalInvestimentos: '',
      totalEmpregos: '',
      totalEmpresas: '',
      fileNameExcel: '',
      showExportExcel: false,
      showExportPDF: false,
      showButtonsTableProtocol: false,
    }
  },
  created() {
    this.loading = true;
    if (this.typeReports == 'Pauta Reunião') {
      ReuniaoRepository.geraRelatorio(this.dataFilters).then(resData => {
        this.createObjecReportsPautaReuniao(resData.data.data);
      });
    } else if (this.typeReports == 'Protocolos Aprovados') {
        ReuniaoRepository.geraRelatorioProtocolosAprovados(this.dataFilters).then(resData => {
          this.createObjecReportsProtocoloAprovados(resData.data.data);
        });
    } else if (this.typeReports == 'Documentos Aptos Reunião') {
      ReuniaoRepository.geraRelatorioDocumentosAptosFiltrados(this.dataFilters).then(resData => {
        this.createObjecReportsDocumentosAptos(resData.data.data);
      });
    } else if (this.typeReports == 'Benefícios Aprovados') {
        ReuniaoRepository.geraRelatorioResolucoesBeneficios(this.dataFilters).then(resData => {
          this.createObjecReportsResolucoesBeneficios(resData.data.data);
      });
    }
  },
  mounted() {
    this.$root.$on('dataTable', (dataFilters) => {
      this.data = [];
      this.fetchData(dataFilters);
    });
  },
  methods: {
    createObjecReportsPautaReuniao(values) {
      this.columnsXls = [];
      this.dataXls = [];
      let arrayData = [];
      let arraysTiposPleitos = ['aditivos', 'projetos', 'monitoramentos', 'agendamentos', 'protocolos', 'termosAcordo'];

      this.headers = [
          {
            text: 'Cnpj',
            align: 'left',
            filterable: true,
            value: 'cnpj',
          },
          { text: 'Empresa', value: 'razaoSocial' },
          { text: 'Programa', value: 'nomePrograma' },
          { text: 'N.Processo', value: 'numeroProcesso' },
          { text: 'Pleito', value: 'pleito' },
      ]
      
      arraysTiposPleitos.forEach(tp => {
        values[tp].map(res => {
          return res['pleito'] = res.motivoPleito;
        });
        arrayData = [... values[tp], ... arrayData];
      });
      this.loading = false;
      this.data = arrayData;
      this.showHeaderTable = true;
      this.showExportExcel = true;
      this.showExportPDF = true;
      this.informationHeader = `${this.dataFilters.tipoReuniao} - ${this.dataFilters.dataInicialReuniao}`;
      this.fileNameExcel = 'Pauta Reunião';
      this.generateExcel(this.data);
    },
    createObjecReportsProtocoloAprovados(value) {
      let arrayData = [];
      this.headers = [
        { text: 'Cnpj', value: 'cnpj' },
        { text: '', value: 'Totais'},
        { text: 'Reunião', value: 'tipoReuniao' },
        { text: 'Empresa', value: 'razaoSocial' },
        { text: 'Programa', value: 'programa' },
        { text: 'Ano', value: 'anoReuniao' },
        { text: 'Investimentos', value: 'investimentos' },
        { text: 'Empregos', value: 'empregos' },
      ]
      this.data = value.protocolos;
      this.loading = false;
      this.showHeaderTable = true;
      this.showExportExcel = true;
      this.showExportPDF = true;
      this.showButtonsTableProtocol = true;
      this.totalInvestimentos = value.totais.totalInvestimentos;
      this.totalEmpregos = value.totais.totalEmpregos;
      this.totalEmpresas = value.totais.totalEmpresa;
      this.fileNameExcel = 'Protocolos Aprovados';
      arrayData = Array.from(value.protocolos);
      arrayData.forEach((val, index) => {
        if ((arrayData.length - 1) == index) {
          let valuetotais = `Total Empresas: ${this.totalEmpresas} Total Investimentos: ${this.totalInvestimentos} Total Empregos: ${this.totalEmpregos}`
          arrayData.push({Totais: valuetotais})
        }
      });
      this.generateExcel(arrayData);
    },
    createObjecReportsResolucoesBeneficios(value) {      
      let arrayData = [];
      this.headers = [
        { text: 'Cnpj', value: 'cnpj' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Programa', value: 'nomePrograma' },
        { text: 'Ano', value: 'anoReuniao' },
        { text: 'Investimentos', value: 'investimentos' },
        { text: 'Empregos', value: 'empregos' },
        { text: 'Atv. economica', value: 'atividadeEconomica' },
        { text: 'CNAE', value: 'cnae' },
        { text: 'Municipio', value: 'municipio' },
        { text: 'Região', value: 'regiao' },
        { text: 'Status', value: 'status' },
      ]
      this.data = value.resolucoesFiscais;
      this.loading = false;
      this.showHeaderTable = true;
      this.showExportExcel = true;
      this.showExportPDF = false;
      this.showButtonsTableProtocol = false;
      this.totalEmpresas = value.length;
      this.fileNameExcel = 'Benefícios Aprovados';
      this.generateExcel(this.data);
    },
    createObjecReportsDocumentosAptos(values) {
      this.columnsXls = [];
      this.dataXls = [];
      let arrayData = [];

      this.headers = [
          {
            text: 'Cnpj',
            align: 'left',
            filterable: true,
            value: 'cnpj',
          },
          { text: 'Empresa', value: 'razaoSocial' },
          { text: 'Programa', value: 'nomePrograma' },
          { text: 'N.Processo', value: 'numeroProcesso' },
          { text: 'Pleito', value: 'tipoDocumento' },
          { text: 'Status', value: 'status' },
          { text: 'Modalidade', value: 'modalidade' },
          { text: 'Dt.Documento', value: 'dataDocumento' },
          { text: 'Dt.Parecer', value: 'dataParecer', width: 105 },
      ]
      
      values.map( x => {
        if (!x.dataParecer) return x.dataParecer = '-';
        if (!x.modalidade) return x.modalidade = '-';
        if (!x.numeroProcesso) return x.numeroProcesso = '-';
        if (!x.dataDocumento)  return x.dataDocumento = '-';
      });
      this.loading = false;
      this.showHeaderTable = true;
      this.showExportExcel = true;
      this.showExportPDF = true;
      this.fileNameExcel = 'Documentos Aptos Reunião';
      this.data = values;
      this.generateExcel(this.data);
    },
    fetchData(dataFilters) {
      this.loading = true;      
      if (this.typeReports == 'Pauta Reunião') {
        ReuniaoRepository.geraRelatorio(dataFilters).then(resData => {
          this.createObjecReportsPautaReuniao(resData.data.data);
        });
      } else if (this.typeReports == 'Protocolos Aprovados') {
        ReuniaoRepository.geraRelatorioProtocolosAprovados(dataFilters).then(resData => {
          this.createObjecReportsProtocoloAprovados(resData.data.data);
        });
      } else if (this.typeReports == 'Documentos Aptos Reunião') {
        ReuniaoRepository.geraRelatorioDocumentosAptosFiltrados(dataFilters).then(resData => {
          this.createObjecReportsDocumentosAptos(resData.data.data);
        });
      } else if (this.typeReports == 'Benefícios Aprovados') {
        ReuniaoRepository.geraRelatorioResolucoesBeneficios(dataFilters).then(resData => {
          this.createObjecReportsResolucoesBeneficios(resData.data.data);
        });
      }
    },
    generateExcel(data) {
      this.columnsXls = [];
      this.dataXls = [];
      this.headers.forEach(header => {
        this.columnsXls.push({ label: header.text, field: header.value })
      });
      this.dataXls = data;
    },
    downloadPDF() {
      if (!this.data.length) {
        this.$emit('getDataFilters', 'Nenhuma informação disponível para Download.');
      } else {
        if (this.typeReports == 'Pauta Reunião') {
          ReuniaoRepository.exportRelatorioPautaReuniãoPDF(this.dataFilters);
        } else if (this.typeReports == 'Protocolos Aprovados') {
          ReuniaoRepository.exportProtocoloAprovadoPDF(this.dataFilters);
        } else if (this.typeReports == 'Documentos Aptos Reunião') {
          ReuniaoRepository.exportRelatorioDocumentosAptos(this.dataFilters);
        }
      }
    }
  }
}
</script>

<style scoped>
    .cardGrid {
        position: relative;
        left: 100px;
        top: 90px;
        margin-right: 7%;
        height: 100%;
        margin-bottom: 2%;
    }
    .btnExport {
      position: relative;
      bottom: 12px;
    }
    .total {
      display: flex;
      justify-content: space-between;
      width: 90%;
    }
    .titleTable {
      position: relative;
      bottom: 12px;
      width: 90%;
    }
    .totalEmp {
      margin-right: 15px;
    }
    .totalInvest {
      margin-right: 15px;
    }
    .teste {
      display: none;
    }
    .none {
      display: none;
    }
</style>