<template>
    <div class="alert">
        <v-alert
            border="left"
            colored-border
            :value="this.show"
            :type="this.type"
            elevation="2"
            width="auto"
            transition="slide-x-reverse-transition"
            dismissible
        >
        {{ this.message }}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'alert',
    props: {
        type: '',
        message: '',
        show: false,
    }
}
</script>

<style scoped>
    .alert {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
</style>