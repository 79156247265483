<template>
  <div>
    <Header></Header>
    <MenuNavigation></MenuNavigation>
    <v-banner icon="mdi-text-box-multiple" elevation="2" single-line class="title">Template de {{this.currentRouteName}}</v-banner>
    <div class="alertAdjust">
      <Alert type="warning" v-bind:message="this.msg" v-bind:show="this.showAlert"></Alert>
    </div>
    <FiltersTemplates v-on:getDataFilters="fetchData" :typeReports="this.currentRouteName" ></FiltersTemplates>
    <v-expand-transition>
      <DataTable v-on:getDataFilters="fetchData" v-if="this.createComponent" :headers="header" :dataFilters="dataFilters" :typeReports="this.currentRouteName"></DataTable>
    </v-expand-transition>
  </div>
</template>

<script>
import MenuNavigation from '@/components/MenuNavigation.vue';
import Header from '@/components/Header.vue';
import DataTable from '@/components/DataTable.vue';
import Filters from '@/components/Filters.vue';
import FiltersTemplates from '@/components/FiltersTemplates.vue';
import Alert from '@/components/Alert.vue';

export default {
  name: 'templates',
  data () { 
    return {
      header: '',
      dataFilters: '',
      createComponent: false,
      showAlert: false,
      msg: '',
    }
  },
  methods: {
    fetchData(value) {
      if (typeof value === 'string') {
        this.showAlert = true;
        this.msg = value;
      } else {
        this.createComponent = true;
        this.dataFilters = value;
        this.$root.$emit('dataTable', this.dataFilters);
      }
    }
  },
  computed: {
    currentRouteName() {
      this.showAlert = false;
      this.createComponent = false;
      this.$root.$emit('filters', this.$route.name);
      return (this.$route.name);
    }
  },
  components: {
    MenuNavigation,
    Header,
    DataTable,
    Filters,
    FiltersTemplates,
    Alert
  },
}
</script>

<style scoped>
  .title {
    position: relative;
    margin-left: 100px;
    margin-top: 15px;
    top: 42px;
  }
  .alertAdjust {
    position: absolute;
    left: 69%;
    width: 30%;
  }
</style>