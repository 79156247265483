export const CreateObjHeaderEmpresasAtivas = ( object ) => ({
    createObjectToExport() {
        
        let arrayValues = [
            { text: 'Cnpj', value: 'cnpj' },
            { text: 'Empresa', value: 'empresa' },
            { text: 'Municipio', value: 'municipio' },
            { text: 'Região', value: 'regiao' },
            { text: 'Programa', value: 'nomePrograma' },
            { text: 'Número Operação', value: 'numeroOperacao' },
            { text: 'Cnae', value: 'cnae' },
            { text: 'Beneficio', value: 'beneficio' },
            { text: 'Retorno', value: 'retorno' },
            { text: 'Investimentos', value: 'investimentos' },
            { text: 'Empregos', value: 'empregos' },
            { text: 'Vigência', value: 'vigencia' },
            { text: 'Atividade Econômica', value: 'atividadeEconomica' }
        ]
        
        return arrayValues;
    },
});