<template>
  <div>
    <Header></Header>
    <MenuNavigation></MenuNavigation>
    <!-- <CardEmpRegister></CardEmpRegister>
    <CardAditivos></CardAditivos> -->
  </div>
</template>

<script>
import MenuNavigation from '@/components/MenuNavigation.vue';
import CardEmpRegister from '@/components/cards-home/CardEmpRegister.vue';
import CardAditivos from '@/components/cards-home/CardAditivos.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'home',
  components: {
    MenuNavigation,
    CardEmpRegister,
    Header,
    CardAditivos,
  },
}
</script>

<style scoped>
  .cardEmp {
    position: relative;
    max-width: 300px;
    height: 206px;
    left: 100px;
    top: 100px;
  }
  .titleCardEmp {
    font-size: 19px;
    font-weight: 800;
  }
</style>