import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import axios from '@/plugins/axios.js';
import store from './stores/auth.js';

Vue.config.productionTip = false;

//Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;

const token = localStorage.getItem('user-token');
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
