import axios from "axios";

const baseDomain = process.env.VUE_APP_BASE_URL_API;
const baseURL = `${baseDomain}/api/v2`;

const getAuthToken = () => localStorage.getItem('user-token');

const authInterceptor = (config) => {
  config.headers.common['Authorization'] = getAuthToken();
  return config;
};

axios.interceptors.request.use(authInterceptor);

// All defaut configuration here
export default axios.create({
  baseURL,
});
