<template>
  <v-app>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import axios from 'axios';
export default {
  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest || err.status === 500 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('AUTH_LOGOUT')
        }
        throw err;
      });
    });
  }
}
</script>