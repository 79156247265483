<template>
  <form @submit.prevent="checkForm" class="form-small" validate>
    <Alert type="warning" v-bind:message="this.errors" v-bind:show="this.showAlert"></Alert>
    <div class="login">
      <v-app id="login">
        <v-content>
          <v-container
            class="fill-height"
            fluid
          >
            <v-row
              align="center"
              justify="center"
            >
              <v-col
                cols="12"
                sm="8"
                md="4"
              >
                <v-card class="elevation-12">
                  <v-toolbar
                    color="#009b06"
                    dark
                    flat
                  >
                  <v-toolbar-title class="colorLabel">Fundo Desenvolvimento Industrial</v-toolbar-title>
                  <v-spacer />
                  </v-toolbar>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                        :rules="[rules.required, rules.min]"
                        label="Usuário"
                        name="usuario"
                        type="text"
                        prepend-icon="account_circle"
                        v-model="username"
                        class="input-group--focused"
                      />
                      <v-text-field
                        :rules="[rules.required, rules.min]"
                        id="password"
                        label="Senha"
                        name="senha"
                        prepend-icon="https"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'" 
                        append-icon="mdi-eye-off"
                        @click:append="showPassword = !showPassword"
                        class="input-group--focused"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                  <v-spacer />
                  <v-btn :loading=loading v-on:click="login" class='white--text' block color="#009b06"><span>Entrar</span></v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-content>
      </v-app>
    </div>
  </form>
</template>

<script>
import Alert from '@/components/Alert.vue';

export default {
  'name': 'login',
  data() {
    return {
      username: '',
      password: '',
      errors: '',
      loading: false,
      showPassword: false,
      showAlert: false,
      rules: {
        required: value => !!value || 'Obrigatório.',
        min: value => (value && value.length >= 3) || 'Necessário ao menos 3 caracteres'
      },
    }
  },
  components: {
    Alert
  },
  methods: {
    login() {
      if (this.validate(this)) {
        const { username, password } = this
        this.loading = true;
        this.$store.dispatch('AUTH_REQUEST', { login: username, senha: password }).then(() => {
          this.loading = false;
          this.$router.push('/home');
        }).catch((error) => {
          this.loading = false
          this.errors = error.message;
          this.showAlert = true;
        });
      }
    },
    validate(value) {
      if (!value.username || !value.password) {return false}
      return true;
    }
  }
}
</script>

<style scoped>
  .colorLabel {
    color:#fff;
  }
</style>
