<template>
  <div>
    <v-card
    class="filters"
    raised
    
  >
    <v-list shapped>
      <v-subheader class="titleFilters">Filtros</v-subheader>
      <v-divider class="mx-4"></v-divider> 
    <v-form>
        <v-container fluid>
            <v-row>
                <v-col v-if="this.typeReports == 'Pauta Reunião'" cols="12" sm="2">
                    <v-select
                        v-model="data.tipoReuniao"
                        :items="itemsTipoReuniao"
                        label="Tipo de Reunião">
                    </v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Protocolos Aprovados' || this.typeReports == 'Benefícios Aprovados'" cols="12" sm="1">
                    <v-select
                        v-model="data.anoReuniao"
                        :items="itemsAnoReuniao"
                        label="Ano">
                    </v-select>
                </v-col>
                
                <!-- Datas Relatorio Documentos Aptos Reunião ------------------------------------------------------------------------------------------------>
                
                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-menu
                        v-model="dataInicioInput"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateFormattedInicial"
                                label="Date Inicio"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker color="green lighten-1" locale="pt-BR" v-model="dataInicial" no-title @input="dataInicioInput = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-menu
                        v-model="dataFimInput"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateFormattedFinal"
                                label="Date Fim"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker color="green lighten-1" locale="pt-BR" v-model="dataFinal" no-title @input="dataFimInput = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-menu
                        v-model="dataInicioParecerInput"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateFormattedInicialParecer"
                                label="Date Inicio Parecer"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker color="green lighten-1" locale="pt-BR" v-model="dataParecerInicio" no-title @input="dataInicioParecerInput = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-menu
                        v-model="dataFimParecerInput"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateFormattedFinalParecer"
                                label="Date Fim Parecer"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker color="green lighten-1" locale="pt-BR" v-model="dataParecerFim" no-title @input="dataFimParecerInput = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col v-if="this.typeReports == 'Pauta Reunião'" cols="12" sm="2">
                    <v-select
                        v-model="data.dataInicialReuniao"
                        :items="itemsData"
                        label="Data da Reunião (Reuniões Abertas)"
                        @input="onChange"
                    ></v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-select
                        v-model="dataDocumentosAptos.status"
                        :items="status"
                        label="Status"
                        clearable
                    ></v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Protocolos Aprovados' || this.typeReports == 'Benefícios Aprovados'" cols="12" sm="2">
                    <v-select
                        v-model="data.dataReuniaoCedin"
                        :items="itemsDataAnoReuniao"
                        label="Data da Reunião"
                        @input="onChange"
                    ></v-select>
                </v-col>

                <v-col  v-if="this.typeReports == 'Documentos Aptos Reunião'"  cols="12" sm="2">
                    <v-text-field
                        :rules="[rules.valid]"
                        v-model="dataDocumentosAptos.cnpj"
                        label="CNPJ"
                        v-mask="['##.###.###/####-##']"
                        clearable
                    ></v-text-field>
                </v-col>

                <v-col v-if="this.typeReports == 'Questionários'" cols="12" sm="2">
                    <v-select
                        v-model="dataQuestionario.questionarios"
                        :items="itemsQuestionarios"
                        label="Tipo de busca"
                        @input="onChange">
                    </v-select>
                </v-col>

                <v-col v-if="this.typeReports !== 'Monitoramento Empresas' && this.typeReports !== 'Documentos Aptos Reunião' && this.typeReports !== 'Empresas Ativas'" cols="12" sm="2">
                    <v-text-field
                        :disabled="disableCnpjQuestinario"
                        @input="onChange"
                        :rules="[rules.valid]"
                        v-model="data.cnpj"
                        label="CNPJ"
                        v-mask="['##.###.###/####-##']"
                    ></v-text-field>
                </v-col>

                <v-col v-if="this.typeReports == 'Questionários'" cols="12" sm="2">
                    <v-select
                        v-model="dataQuestionario.tipoPleito"
                        :items="itensPleitosQuestionarios"
                        label="Tipos de Pleitos"
                        :disabled="disableCnpjQuestinario"
                    ></v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Monitoramento Empresas'" cols="12" sm="2">
                    <v-select
                        v-model="dataMonitoramento.anoExercicio"
                        :items="itemsAnoExercicio"
                        label="Ano Exercicio">
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="this.typeReports == 'Pauta Reunião'" cols="12" sm="2">
                    <v-text-field
                        v-model="data.numeroProcesso"
                        label="Número do Processo"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-text-field
                        v-model="dataDocumentosAptos.numeroProcesso"
                        label="Número do Processo"
                    ></v-text-field>
                </v-col>
                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-select
                        v-model="dataDocumentosAptos.modalidade"
                        :items="itensModalidade"
                        label="Modalidades"
                    ></v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="2">
                    <v-select
                        v-model="dataDocumentosAptos.tipoDocumento"
                        :items="itensPleitosDocAptos"
                        label="Tipos de Pleitos"
                    ></v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Pauta Reunião'" cols="12" sm="3">
                    <v-select
                        v-model="data.tipoDocumento"
                        :items="itemTipoPleito"
                        label="Tipos de Pleitos"
                    ></v-select>
                </v-col>
                <v-col v-if="this.typeReports == 'Pauta Reunião'" cols="12" sm="1">
                    <v-select
                        v-model="data.programa"
                        :items="itemProgramas"
                        label="Programa"
                    ></v-select>
                </v-col>
                <v-col v-if="this.typeReports == 'Documentos Aptos Reunião'" cols="12" sm="1">
                    <v-select
                        v-model="dataDocumentosAptos.programa"
                        :items="itemProgramas"
                        label="Programa"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="this.typeReports == 'Monitoramento Empresas' || this.typeReports == 'Empresas Ativas' || this.typeReports == 'Questionários'">
                <v-col v-if="this.typeReports == 'Monitoramento Empresas'|| this.typeReports == 'Questionários'" cols="12" sm="6">
                    <v-btn v-on:click="clear" class='white--text' color="#009b06"><span>Limpar</span></v-btn>
                    
                    <div class="btnExport" v-on:click="sendFilters()">
                        <ExportExcel  
                        :data="dataXls"
                        :columns="columnsXls"
                        :filename="fileNameExcel"
                        :sheetname="informationHeader">
                        </ExportExcel>
                    </div>
                </v-col>
                <v-col v-if="this.typeReports == 'Empresas Ativas'" cols="12" sm="6">
                    
                    <div class="btnExportEmpresasAtivas" v-on:click="sendFilters()">
                        <ExportExcel  
                        :data="dataXls"
                        :columns="columnsXls"
                        :filename="fileNameExcel"
                        :sheetname="informationHeader">
                        </ExportExcel>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" sm="6">
                    <v-btn v-on:click="clear" class='white--text' color="#009b06"><span>Limpar</span></v-btn>
                    <v-btn v-if="this.typeReports !== 'Questionários'" :disabled="disabledSend" v-on:click="sendFilters" class='white--text buttons' color="#009b06"><span>Pesquisar</span></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
    </v-list>
  </v-card>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask';
import Repository from '@/repositories/RepositoryFactory.js';
import { CreateObjectFilters } from '@/factory/CreateObjFilters.js';
import { CreateObjHeaderToExport } from '@/factory/CreateObjHeaderToExport.js';
import { CreateObjHeaderEmpresasAtivas } from '@/factory/CreateObjHeaderEmpresasAtivas.js';
import { CreateObjHeaderQuestionario } from '@/factory/CreateObjHeaderQuestionario.js';
const ReuniaoRepository = Repository.get("reuniao");
const MonitoramentoRepository = Repository.get('monitoramento');
const EmpresaRepository = Repository.get('empresa');
const QuestionarioRepository = Repository.get('questionarios');
import ExportExcel from '@/components/ExportExcel.vue';

export default {
  name: 'filters',
  created() {
      this.fetchDataFilters();
  },
  mounted() {
    this.$root.$on('filters', (typeReports) => {
        console.log(typeReports);
        this.fetchDataFilters(typeReports);
    });
  },
  data () {
    return {
        data: {dataInicialReuniao: 'Todas', cnpj: '', tipoPleito: 'Todos', tipoReuniao: 'Todas', programa: 'Todos', numeroProcesso: '', anoReuniao: '', dataReuniaoCedin: ''},
        dataDocumentosAptos: {cnpj: '', dataInicial: '', dataFinal: '', dataParecerInicio: '', dataParecerFim: '', status: null, numeroProcesso: null, modalidade: null, tipoDocumento: null },
        dataMonitoramento: { anoExercicio: 'Todos'},
        dataQuestionario: { questionarios: 'CNPJ', tipoPleito: ''},
        itemsTipoReuniao: ['TECNICA', 'CEDIN'],
        itemProgramas: ['Todos', 'PROVIN', 'PCDM', 'FDCV', 'PIER'],
        itemTipoPleito: ['Todos', 'Pareceres de Visita', 'Pendências - Formulários', 'Suspensão e retomada de termos'],
        itensModalidade: ['Todos', 'Implantação', 'Modernização', 'Ampliação', 'Recuperação', 'Diversificação'],
        itensPleitosDocAptos: ['Todos', 'Protocolo', 'Pedido de importação de matéria prima', 'Requerimento de Resolução', 'Pedido máquina', 'Projeto Técnico', 'Projeto - Pedido Prorrogação',
                               'Aditivo - Alterar o benefício', 'Aditivo - Alterar a capacidade de produção', 'Aditivo - Alteração de representantes legais', 'Aditivo - Alterar a razão social',
                               'Aditivo - Alterar o CGF', 'Aditivo - Alterar a composição acionária', 'Aditivo - Alterar o tipo de sociedade'],
        status: ['APROVADO', 'CRIADO', 'PARECER_TECNICO_FINALIZADO', 'CONCESSAO_DE_VISTA_TECNICA'],
        itemsData: [],
        itemsAnoReuniao: [],
        itemsDataAnoReuniao: [],
        itemsAnoExercicio: ['Todos'],
        itemsQuestionarios: ['CNPJ', 'Todos'],
        itensPleitosQuestionarios: ['Todos'],
        result: [],
        rules: {
            valid: value => ( this.validCnpj(value) ) || 'Cnpj Inválido'
        },
        disabledSend: false,
        disableCnpjQuestinario: false,
        date: new Date().toISOString().substr(0, 10),
        dateFormattedFinal: null,
        dateFormattedInicial: null,
        dateFormattedFinalParecer: null,
        dateFormattedInicialParecer: null,
        dataInicioInput: false,
        dataFimInput: false,
        dataInicioParecerInput: false,
        dataFimParecerInput: false,
        dataInicial: null,
        dataFinal: null,
        dataParecerInicio: null,
        dataParecerFim: null,
        dataXls : [],
        columnsXls : [],
        fileNameExcel: '',
        informationHeader: '',
        headers: '',
    }
  },
  props: {
      getDataFilters: '',
      typeReports: '',
  },
  components: {
    ExportExcel,
  },
  directives: {mask},
  computed: {
    computedDateFormatted (value) {
        return this.formatDate(value)
    },
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
    dataFinal() {
        if (this.dataFinal) {
            const [year, month, day] = this.dataFinal.split('-');
            this.dateFormattedFinal = `${day}/${month}/${year}`;
            this.dataDocumentosAptos.dataFinal = this.parseDate(this.dateFormattedFinal);
        }
    },
    dataInicial() {
        if (this.dataInicial) {
            const [year, month, day] = this.dataInicial.split('-');
            this.dateFormattedInicial = `${day}/${month}/${year}`;
            this.dataDocumentosAptos.dataInicial = this.parseDate(this.dateFormattedInicial);
        }
    },
    dataParecerFim() {
        if (this.dataParecerFim) {
            const [year, month, day] = this.dataParecerFim.split('-');
            this.dateFormattedFinalParecer = `${day}/${month}/${year}`;
            this.dataDocumentosAptos.dataParecerFim = this.parseDate(this.dateFormattedFinalParecer);
        }
    },
    dataParecerInicio() {
        if (this.dataParecerInicio) {
            const [year, month, day] = this.dataParecerInicio.split('-');
            this.dateFormattedInicialParecer = `${day}/${month}/${year}`;
            this.dataDocumentosAptos.dataParecerInicio = this.parseDate(this.dateFormattedInicialParecer);
        }
    }
  },
  methods: {
    fetchDataFilters(typeReports) {
        this.disabledSend = false;
        (typeReports) ? this.typeReports = typeReports : this.typeReports;
        if (this.typeReports == 'Pauta Reunião') {
            ReuniaoRepository.getCreateds("CRIADA").then(res => {
                if (!res.data['data'].length) { 
                    this.$emit('getDataFilters', 'Não existem reuniões abertas para consulta.');
                    this.disabledSend = true;
                }
                res.data['data'].forEach(objReu => {
                    this.itemsData.push(objReu.dataReuniao)
                    this.result.push(objReu);
                    if (this.typeReports == 'Pauta Reunião') this.data.tipoReuniao = objReu.tipoReuniao;
                    this.data.dataInicialReuniao = objReu.dataReuniao
                });
            });
            ReuniaoRepository.getPleitos().then(res => {
                res.data['data'].forEach(val => {
                    if (val.tipoDocumento) {
                        if (!val.motivoPleito) {
                            this.itemTipoPleito.push(`${val.tipoDocumento}`);
                        } else if (val.tipoDocumento == 'Protocolo') {
                            this.itemTipoPleito.push(`${val.motivoPleito}`);
                        } else {
                            this.itemTipoPleito.push(`${val.tipoDocumento} - ${val.motivoPleito}`);
                        }
                    }
                    
                    this.result.push(val);
                });
            });
        } else {
            //TODO: Create validation for separate methods, two requisitions.
            ReuniaoRepository.getAnosProtocolos().then( res => {
                let arrayAno;
                let ano;
                for (let prop in res.data['data']) {
                    arrayAno = res.data['data'][prop];
                    ano = prop;
                    arrayAno.forEach(val => {
                        this.itemsDataAnoReuniao.push(val.data);
                        this.itemsAnoReuniao.push(ano);
                        this.data.dataReuniaoCedin = val.data;
                        this.data.anoReuniao = ano;
                    });
                }
            });

            MonitoramentoRepository.recuperarTodosMonitoramentos().then( res => {
                res.data['data'].forEach( x => {
                    this.itemsAnoExercicio.push(x.anoExercicio);
                });
            });
        }
    },
    formatDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
    },
    parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    },
    sendFilters() {
        let filterSend = {};
        if (this.typeReports == 'Documentos Aptos Reunião') {
            this.createObjFiltersDocumentosAptos(filterSend);
        } else if (this.typeReports == 'Monitoramento Empresas') {
            this.exportMonitoramentoPlanilhao(filterSend);
        } else if (this.typeReports == 'Empresas Ativas') {
            this.exportEmpresasAtivasXLS(filterSend);
        }  else if (this.typeReports == 'Questionários') {
            this.exportRelatorioQuestionarios(filterSend);
        } else {
            for (var prop in this.data) {
                if (!this.data.hasOwnProperty(prop)) continue;
                if (this.data[prop]) {
                    if (this.data[prop] !== "Todas" && this.data[prop] !== "Todos") {
                        filterSend[prop] = this.data[prop]
                    }
                }
            }
            if (filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Aditivo") !== -1) {
                filterSend['motivoPleito'] =  filterSend.tipoDocumento.substr(10,filterSend.tipoDocumento.length);
                delete filterSend['tipoDocumento'];
            } else if (filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Pedido máquina") !== -1 || filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Pedido de importação de matéria prima") !== -1 || filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Requerimento de Resolução") !== -1) {
                filterSend['motivoPleito'] = filterSend.tipoDocumento;
                delete filterSend['tipoDocumento'];
            }
            this.$emit('getDataFilters', filterSend);
        }
         
    },
    createObjFiltersDocumentosAptos(filterSend) {
        const { createFilters } = CreateObjectFilters(filterSend, this.dataDocumentosAptos);
        this.$emit('getDataFilters', createFilters());
    },
    exportMonitoramentoPlanilhao(filters) {
        const { createFilters } = CreateObjectFilters(filters, this.dataMonitoramento);
        this.fileNameExcel = 'Formulários Monitoramento Empresas';
        this.informationHeader = 'Empresas';
        let arrayDataResponse;
        MonitoramentoRepository.gerarRelMonitoramentoPlanilhaoFiltrado(createFilters()).then( res => {
            arrayDataResponse = res.data['data'];
            const { createObjectMonitoramentoPlanilhao } = CreateObjHeaderToExport( arrayDataResponse[0] );
            this.generateExcel(arrayDataResponse, createObjectMonitoramentoPlanilhao());    
        });
    },
    exportRelatorioQuestionarios(filters) {
        if (this.dataQuestionario.questionarios !== 'Todos') {
            this.dataQuestionario['cnpj'] = this.data.cnpj.replace(/[^\d]+/g,"");
        }
        const { createFilters } = CreateObjectFilters(filters, this.dataQuestionario);
        this.fileNameExcel = 'Relatorio Questionários';
        this.informationHeader = 'Empresas';
        let arrayDataResponse;
        QuestionarioRepository.recuperarRelatorioQuestinarioFiltrado(createFilters()).then( res => {
            arrayDataResponse = res.data['data'].map( x => {
                let jsonParsed = JSON.parse(x.json);
                if (x.tipoPleito == 'Geral') {
                    x['quao_efetivo_atendimento_equipe'] = jsonParsed.equipe.questions['quao_efetivo_atendimento_equipe'];
                    x['quais_recomendacoes'] = jsonParsed.equipe.questions['quais_recomendacoes'];
                    x['quao_amigavel_interface_sistema'] = jsonParsed.sistema.questions['quao_amigavel_interface_sistema'];
                    x['quao_bemsucedido_realizar_funcao_propoe'] = jsonParsed.sistema.questions['quao_bemsucedido_realizar_funcao_propoe'];
                    x['forma_geral_quao_satisfetio_sistema'] = jsonParsed.sistema.questions['forma_geral_quao_satisfetio_sistema'];
                    x['quais_recomendacoes'] = jsonParsed.sistema.questions['quais_recomendacoes'];
                } else {
                    x['forma_geral_quao_satisfetio_sistema'] = jsonParsed.sistema.questions['forma_geral_quao_satisfetio_sistema'];
                    x['quais_recomendacoes'] = jsonParsed.sistema.questions['quais_recomendacoes'];
                }
                return x;
            });
            const { createObjtHeaderQuestionario } = CreateObjHeaderQuestionario( arrayDataResponse[0] );
            this.generateExcel(arrayDataResponse, createObjtHeaderQuestionario());    
        });
    },
    exportEmpresasAtivasXLS(filters) {
        this.fileNameExcel = 'Empresas Ativas';
        this.informationHeader = 'Empresas';
        let arrayDataResponse;

        EmpresaRepository.recuperarEmpresasAtivas().then( res => {
            arrayDataResponse = res.data['data'];
            const { createObjectToExport } = CreateObjHeaderEmpresasAtivas( arrayDataResponse[0] );
            this.generateExcel(arrayDataResponse, createObjectToExport());    
        });
    },

    generateExcel(data, headers) {
      this.columnsXls = [];
      this.dataXls = [];
      headers.forEach(header => {
        this.columnsXls.push({ label: header.text, field: header.value })
      });
      this.dataXls = data;
    },

    clear() {
        this.data.cnpj = '';
        this.data.tipoDocumento = '';
        this.data.tipoReuniao = 'TECNICA';
        this.data.programa = '';
        this.data.numeroProcesso = '';
        this.dataDocumentosAptos.cnpj = '';
        this.dataDocumentosAptos.numeroProcesso = '';
        this.dataDocumentosAptos.programa = '';
        this.dataDocumentosAptos.tipoDocumento = '';
        this.dataDocumentosAptos.dataInicial = '';
        this.dataDocumentosAptos.dataFinal = '';
        this.dataFinal = null;
        this.dataInicial = null;
        this.dataDocumentosAptos.status = '';
        this.dataDocumentosAptos.modalidade = '';
        this.dataDocumentosAptos.tipoDocumento = '';
        this.dataDocumentosAptos.cnpj = '';
        this.dataDocumentosAptos.dataParecerInicio = '';
        this.dataDocumentosAptos.dataParecerFim = '';
        this.dataParecerInicio = null;
        this.dataParecerFim = null;
        this.dateFormattedInicial = null;
        this.dateFormattedFinal = null;
        this.dateFormattedFinalParecer = null;
        this.dateFormattedInicialParecer = null;
        this.dataDocumentosAptos.programa = '';
        this.dataQuestionario.tipoPleito = '';
    },
    onChange(event) {
        if (event && event == "Todos") {
            this.disableCnpjQuestinario = true;
        } else {
            this.disableCnpjQuestinario = false;
        }

        if (event.length == 18) {
            event = event.replace(/[^\d]+/g,"");
            QuestionarioRepository.recuperarRelatorioQuestinarioFiltrado({cnpj: event}).then( res => {
                let arraytiposPleitos = [];
                res['data']['data'].forEach( x => {
                    this.itensPleitosQuestionarios.push(x.tipoPleito);
                });
            });
        }

        this.result.forEach(val => {
            if (event == val.dataReuniao) {
                this.data.tipoReuniao = val.tipoReuniao;
                this.itemsTipoReuniao.push(val.tipoReuniao);
            }
        });
    },
    validCnpj(cnpj) {
        if (cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g, '');

            if (cnpj == '') {
                this.disabledSend = false;
                return true;
            } 

            if (cnpj.length != 14) {
                this.disabledSend = true;
                return false;
            }
                

        
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999") {
                    this.disabledSend = true;
                    return false;
                }
                
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0, tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) return false;
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1)) {
                this.disabledSend = true;
                return false;
            }

            this.disabledSend = false;
            return true;
        }
    }
  }
}
</script>

<style scoped>
  .filters {
    position: relative;
    margin-right: 7%;
    left: 100px;
    top: 60px;
    height: 330px;
    margin-bottom: 0%;
  }
  .titleFilters {
    font-size: 19px;
    font-weight: 800;
  }
  .buttons {
    margin-left: 3%;
  }
  .btnExport {
    position: relative;
    left: 97px;
    bottom: 43px;
  }
  .btnExportEmpresasAtivas {
    position: relative;
    right: 5px;
    bottom: 5px;
  }
</style>