<template>
    <v-btn :loading="downloadingXls" v-on:click="downloadXls" class="ma-2" tile outlined color="success">
        <v-icon left>mdi-download</v-icon> Baixar xls
    </v-btn>
</template>

<script>
import XLSX from 'xlsx/xlsx';

export default {
    name: 'exportExcel',
    data() {
        return {
            downloadingXls: false,
        }
    },
    created() {
        this.$root.$refs.ExportExcel = this;
    },
    props: {
        columns: {
            type: Array,
            default: []
        },
        data: {
            type: Array,
            default: []
        },
        filename: {
            type: String,
            default: 'excel'
        },
        sheetname: {
            type: String,
            default: 'SheetName'
        },
    },
    methods: {
        downloadXls() {
            if (!this.waitRequest) {
                this.downloadingXls = true;
                let createXLSLFormatObj = [];
                let newXlsHeader = [];
                let vm = this;
                if (vm.columns.length === 0){
                    this.downloadingXls = false;
                    return;
                }
                if (vm.data.length === 0){
                    this.downloadingXls = false;
                    return;
                }
                vm.columns.forEach((value, index) => {
                    newXlsHeader.push(value.label);
                });
                createXLSLFormatObj.push(newXlsHeader);
                vm.data.forEach((value, index) => {
                    let innerRowData = [];
                    vm.columns.forEach((col, index) => {
                        if (col.dataFormat && typeof val.dataFormat === 'function') {
                            innerRowData.push(col.dataFormat(value[col.field]));
                        } else {
                            innerRowData.push(value[col.field]);
                        }
                    });
                    createXLSLFormatObj.push(innerRowData);
                });
                let filename = vm.filename + ".xlsx";
                let ws_name = vm.sheetname;
                let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, filename);
                this.downloadingXls = false;
            }
        }
    }
}
</script>

<style scoped>
</style>