import axios from '@/plugins/axios.js';
const resource = 'relatorio/reuniao';

export default {
    get() {
        return axios.get(`${resource}`);
    },
    getCreateds(payload) {
        return axios.get(`${resource}/recuperarReunioesAbertas?statusReuniao=${payload}`);
    },
    geraRelatorio(payload) {
        return axios.post(`${resource}/recuperarReuniaoRelatoriosFiltrado`, payload);
    },
    delete(id) {
        return axios.delete(`${resource}/${id}`)
    },
    getPleitos() {
        return axios.get(`${resource}/recuperarTiposPleitos`);
    },
    getAnosProtocolos() {
        return axios.get(`${resource}/recuperarAnosReuniaoProtocolo`);
    },
    geraRelatorioProtocolosAprovados(payload) {
        return axios.post(`${resource}/recuperarRelatorioProcolosFiltrado`, payload);
    },
    geraRelatorioDocumentosAptos() {
        return axios.get(`${resource}/recuperarDocumentosAptos`);
    },
    geraRelatorioDocumentosAptosFiltrados(payload) {
        return axios.post(`${resource}/recuperarDocumentosAptosFiltrados`, payload);
    },
    exportProtocoloAprovadoPDF(payload) {
        return axios({
            url: `${resource}/export/relatorioProtocolo`,
            method: 'post',
            data: payload,
            responseType: 'blob',
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'Relatório de Protocolos Aprovados.pdf');
             document.body.appendChild(link);
             link.click();
          });
    },
    exportRelatorioPautaReuniãoPDF(payload) {
        return axios({
            url: `${resource}/export/reuniaoRelatoriosFiltrado`,
            method: 'post',
            data: payload,
            responseType: 'blob',
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'Relatório de Pauta Reunião.pdf');
             document.body.appendChild(link);
             link.click();
          });
    },
    exportRelatorioDocumentosAptos(payload) {
        return axios({
            url: `${resource}/export/relatorioDocumentosAptos`,
            method: 'post',
            data: payload,
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatório de Documentos Aptos Reunião.pdf');
            document.body.appendChild(link);
            link.click();
        });
    },
    geraRelatorioResolucoesBeneficios(payload) {
        return axios.post(`${resource}/recuperarResolucoesFiscaisFiltrados`, payload);
    },
    generateTemplateDocumentosAptos(payload) {
        return axios({
            url: `${resource}/export/templatePautaReuniao`,
            method: 'post',
            data: payload,
            responseType: 'blob',
        });
    },
};