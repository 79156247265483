<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      :color="color"
      :expand-on-hover="expandOnHover"
      :mini-variant="miniVariant"
      :right="right"
      :src="bg"
      absolute
      dark
      permanent
      height="105%"
    >
    <v-list
        dense
        nav
        class="py-0"
    >
        <v-list-item two-line :class="miniVariant && 'px-0'">
        <v-list-item-avatar>
            <img src="https://brasao.org/wp-content/uploads/2018/10/brasao-do-ceara.png">
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title>{{this.$store.state.user.nome}}</v-list-item-title>
            <v-list-item-subtitle>{{this.$store.state.user.perfis[0]}}</v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
        
        <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>


        <v-list-item-content>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-title class="titleMenu" v-on:click="goRoute(item.link)" >{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item 
              v-for="children in item.childrens"
              :key="children.title"
              link
            >
              <v-list-item-title class="subTitleMenu" v-on:click="goRoute(children.link)">{{ children.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-item-content>
        </v-list-item>
        <v-btn width="100%" :loading=loading block outlined text v-on:click="logout">Sair</v-btn>
        <v-btn width="100%" disabled block outlined text>v 1.2.0</v-btn>
    </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  export default {
    name: 'menuNavigation',
    data () {
      return {
        drawer: true,
        loading: false,
        items: [
          { title: 'Inicio', icon: 'mdi-view-dashboard', link: '/home', childrens: []},
          { title: 'Relatórios', icon: 'mdi-ballot-outline', childrens: [
            {title: 'Pauta Reunião', 'link': '/reports/pautaReuniao'},
            {title: 'Protocolos Aprovados', 'link': '/reports/protocoloAprovados'},
            {title: 'Documentos aptos reunião', 'link': '/reports/documentosAptosReuniao'},
            {title: 'Monitoramento Empresas', 'link': '/reports/monitoramentoEmpresas'},
            {title: 'Empresas Ativas', 'link': '/reports/empresasAtivas'},
            {title: 'Benefícios Aprovados ', 'link': '/reports/resolucoesBeneficios'},
            {title: 'Questionarios ', 'link': '/reports/questionarios'}]},
          { title: 'Templates', icon: 'mdi-text-box-multiple', childrens: [
            {title: 'Pauta Reunião', link: '/templates/pautaReuniao'}
          ]}
          ],
        color: '#009b06',
        right: false,
        miniVariant: false,
        expandOnHover: true,
        background: false,
      }
    },
    methods: {
      goRoute(route) {
        this.$router.push(route)
      },
      logout() {
        this.loading = true;
        this.$store.dispatch('AUTH_LOGOUT').then(() => {
          this.loading = false;
          this.$router.push('/')
        });
      }
    },
    computed: {
      bg () {
        return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
      },
    },
  }
</script>

<style scoped>
  .titleMenu {
    font-size: 40px; 
  }
  .subTitleMenu {
    font-size: 13px;
  }
</style>