export const CreateObjHeaderToExport = ( object ) => ({
    createObjectMonitoramentoPlanilhao() {
        
        let arrayValues = [
            { text: 'Situação', value: 'situacao' },
            { text: 'Razão Social', value: 'razaoSocial' },
            { text: 'Nome Fantasia', value: 'nomeFantasia' },
            { text: 'Cnpj', value: 'cnpj' },
            { text: 'Data Fundação', value: 'dataFundacao' },
            { text: 'Pais de origem', value: 'paisDeOrigem' },
            { text: 'Estado de Origem', value: 'estadoDeOrigem' },
            { text: 'Municipio', value: 'municipio' },
            { text: 'Região', value: 'regiao' },
            { text: 'Cnae', value: 'cnae' },
            { text: 'Bairro', value: 'bairro' },
            { text: 'Endereco', value: 'endereco' },
            { text: 'Numero', value: 'numero' },
            { text: 'Complemento', value: 'complemento' },
            { text: 'Inscrição Estadual', value: 'inscricaoEstadual' },
            { text: 'Tipo Sociedade', value: 'tipoSociedade' },
            { text: 'Telefone', value: 'telefone' },
            { text: 'Fax', value: 'fax' },
            { text: 'Email', value: 'email' },
            { text: 'Site', value: 'site' },
            { text: 'Data Inicio Atividade', value: 'dataInicioAtividade' },
            { text: 'Faturamento', value: 'faturamento' },
            { text: 'Valot Total Ano', value: 'valorTotalAn' },
            { text: 'Escala', value: 'escala' },
            { text: 'Qualidade', value: 'qualidade' },
            { text: 'Processo Produtivo', value: 'processoProdutivo' },
            { text: 'Preço', value: 'preco' },
            { text: 'Pontualidade', value: 'pontualidade' },
            { text: 'Insumos Outros', value: 'insumosOutros' },
            { text: 'Materia Prima Insumos', value: 'materiaPrimaInsumos' },
            { text: 'Maquinas Equipamentos', value: 'maquinasEquipamentos' },
            { text: 'Valor Maq Equip Imp', value: 'valorMaqEquipImp' },
            { text: 'Valor DiferimentoICMS', value: 'valorDiferimentoICMS' },
            { text: 'Valor Importacao MatPrimaInsumos', value: 'valorImportacaoMatPrimaInsumos' },
            { text: 'Valor ICMS Mat Prima', value: 'valorDiferimentoICMS2' },
            { text: 'Comodato', value: 'comodato' },
            { text: 'Comodato Descricao', value: 'comodatoDescricao' },
            { text: 'Galpao', value: 'galpao' },
            { text: 'GalpaoDescricao', value: 'galpaoDescricao' },
            { text: 'agua', value: 'agua' },
            { text: 'Agua Descricao', value: 'aguaDescricao' },
            { text: 'esgoto', value: 'esgoto' },
            { text: 'Esgoto Descricao', value: 'esgotoDescricao' },
            { text: 'Comunicacao', value: 'comunicacao' },
            { text: 'Comunicacao Descricao', value: 'comunicacaoDescricao' },
            { text: 'Energia', value: 'energia' },
            { text: 'Energia Descricao', value: 'energiaDescricao' },
            { text: 'Terra planagem', value: 'terraplanagem' },
            { text: 'Terra planagem descricao', value: 'terraplanagemDescricao' },
            { text: 'Acesso', value: 'acesso' },
            { text: 'Acesso Descricao', value: 'acessoDescricao' },
            { text: 'Outros', value: 'outros' },
            { text: 'Outros Descricao', value: 'outrosDescricao' },
            { text: 'lei8167', value: 'lei8167' },
            { text: 'lei8313', value: 'lei8313' },
            { text: 'lei11438', value: 'lei11438' },
            { text: 'lei8069', value: 'lei8069' },
            { text: 'lei12213', value: 'lei12213' },
            { text: 'lei12715', value: 'lei12715' },
            { text: 'lei12715Pronas', value: 'lei12715Pronas' },
            { text: 'Outros Federal', value: 'outrosFederal' },
            { text: 'Outros Federal Descricao', value: 'outrosFederalDescricao' },
            { text: 'Iptu', value: 'iptu' },
            { text: 'ISS', value: 'iss' },
            { text: 'Terreno', value: 'terreno' },
            { text: 'Aluguel', value: 'aluguel' },
            { text: 'Energia', value: 'energia' },
            { text: 'Obras', value: 'obras' },
            { text: 'Outros Municipal', value: 'outrosMunicipal' },
            { text: 'Outros Municipal Descricao', value: 'outrosMunicipalDescricao' },
            { text: 'Invest Realizados Acumulado Total', value: 'investRealizadosAcumuladoTotal' },
            { text: 'Potencia Instalada', value: 'potenciaInstalada' },
            { text: 'Consumo Estimado Ano', value: 'consumoEstimadoAno' },
            { text: 'Energia Nao Aplica', value: 'energiaNaoAplica' },
            { text: 'Energia Renovavel Eolica', value: 'energiaRenovavelEolica' },
            { text: 'Energia Renovavel Fotovoltaica', value: 'energiaRenovavelFotovoltaica' },
            { text: 'Energia Renovavel Biomassa', value: 'energiaRenovavelBiomassa' },
            { text: 'Agua Bruta', value: 'aguaBruta' },
            { text: 'Agua Tratada', value: 'aguaTratada' },
            { text: 'Agua Desalinizada', value: 'aguaDesalinizada' },
            { text: 'Aagua Reuso', value: 'aguaReuso' },
            { text: 'Esgoto FossaSeptica', value: 'esgotoFossaSeptica' },
            { text: 'Esgoto Rede Publica', value: 'esgotoRedePublica' },
            { text: 'Esgoto Tratado Internamente', value: 'esgotoTratadoInternamente' },
            { text: 'Esgoto Outros', value: 'esgotoOutros' },
            { text: 'Pessoal Registrado MascTotal', value: 'pessoalRegistradoMascTotal' },
            { text: 'Pessoal Registrado FemTotal', value: 'pessoalRegistradoFemTotal' },
            { text: 'Pessoal Terceirizado MascTotal', value: 'pessoalTerceirizadoMascTotal' },
            { text: 'Pessoal Terceirizado FemininoTotal', value: 'pessoalTerceirizadoFemininoTotal' },
            { text: 'Empregados Jovem Aprendiz Masc', value: 'empregadosJovemAprendizMasc' },
            { text: 'Empregados JovemAprendiz Fem', value: 'empregadosJovemAprendizFem' },
            { text: 'Empregados EscEstMasc', value: 'empregadosEscEstMasc' },
            { text: 'Empregados EscEstFem', value: 'empregadosEscEstFem' },
            { text: 'Percentual Recrutados Municipio', value: 'percentualRecrutadosMunicipio' },
            { text: 'Percentual Recrutados Fora Municipio', value: 'percentualRecrutadosForaMunicipio' },
            { text: 'Percentual Recrutados Fora Estado', value: 'percentualRecrutadosForaEstado' },
            { text: 'Percentual Recrutados Fora Pais', value: 'percentualRecrutadosForaPais' },
            { text: 'Tempo Med Perm Emp RegEmpresa', value: 'tempoMedPermEmpRegEmpresa' },
            { text: 'Mao De Obra Baixa Escolaridade', value: 'maoDeObraBaixaEscolaridade' },
            { text: 'Mao De Obra SemNivelTec', value: 'maoDeObraSemNivelTec' },
            { text: 'Mao De Obra SemQualif Especifica', value: 'maoDeObraSemQualifEspecifica' },
            { text: 'Mao De Obra Sem Experiencia', value: 'maoDeObraSemExperiencia' },
            { text: 'Mao De Obra InsuficienteMunicipio', value: 'maoDeObraInsuficienteMunicipio' },
            { text: 'Mao De Obra PretensaoSalarialAcimaMercado', value: 'maoDeObraPretensaoSalarialAcimaMercado' },
            { text: 'Mao De Obra MercTrabConcorrido', value: 'maoDeObraMercTrabConcorrido' },
            { text: 'Mao De Obra Outros', value: 'MaoDeObraOutros' },
            { text: 'Mao De Obra Outros Descricao', value: 'maoDeObraOutrosDescricao' },
            { text: 'Recrutamento Sine', value: 'recrutamentoSine' },
            { text: 'Recrutamento Indic Funcionarios', value: 'recrutamentoIndicFuncionarios' },
            { text: 'Recrutamento Propria Empresa', value: 'recrutamentoPropriaEmpresa' },
            { text: 'Recrutamento Emp Recrutadora', value: 'recrutamentoEmpRecrutadora' },
            { text: 'Recrutamento Prog Oportunizar', value: 'recrutamentoProgOportunizar' },
            { text: 'Recrutamento Outros', value: 'recrutamentoOutros' },
            { text: 'Recrutamento Outros Descricao', value: 'recrutamentoOutrosDescricao' },
            { text: 'Beneficio RefeicaoLocal', value: 'beneficioRefeicaoLocal' },
            { text: 'Beneficio Vale Cultura', value: 'beneficioValeCultura' },
            { text: 'Beneficio PlanoSaude', value: 'beneficioPlanoSaude' },
            { text: 'Beneficio MedicoEmp', value: 'beneficioMedicoEmp' },
            { text: 'Beneficio Plano Odonto', value: 'beneficioPlanoOdonto' },
            { text: 'Beneficio OdontoEmp', value: 'beneficioOdontoEmp' },
            { text: 'Beneficio Plr', value: 'beneficioPlr' },
            { text: 'Beneficio Bolsa Estudos', value: 'beneficioBolsaEstudos' },
            { text: 'Beneficio Outros', value: 'beneficioOutros' },
            { text: 'Beneficio Outros Descricao', value: 'beneficioOutrosDescricao' },
            { text: 'Idade Func Contratados Ate 20', value: 'idadeFuncContratadosAte20' },
            { text: 'Idade Func Contratados Ate 30', value: 'idadeFuncContratadosAte30' },
            { text: 'Idade Func Contratados Ate 40', value: 'idadeFuncContratadosAte40' },
            { text: 'Idade FuncContratados Ate 50', value: 'idadeFuncContratadosAte50' },
            { text: 'Idade Func Contratados Ate 60', value: 'idadeFuncContratadosAte60' },
            { text: 'Idade Func Contratados Acima 60', value: 'idadeFuncContratadosAcima60' },
            { text: 'Ocupacoes Contratadas Masc', value: 'ocupacoesContratadasMasc' },
            { text: 'Ocupacoes Contratadas Fem', value: 'ocupacoesContratadasFem' },
            { text: 'Ocupacoes Contratadas Cearense', value: 'ocupacoesContratadasCearense' },
            { text: 'Ocupacoes Contratadas Nao Cearense', value: 'ocupacoesContratadasNaoCearense' },
            { text: 'Ocupacoes Contratadas Estrangeiro', value: 'ocupacoesContratadasEstrangeiro' },
            { text: 'Dificuldade Infra Ceara', value: 'dificuldadeInfraCeara' },
            { text: 'Dificuldade Infra Especificacao', value: 'dificuldadeInfraEspecificacao' },
            { text: 'Problemas Poluicao AmbientalEmp', value: 'problemasPoluicaoAmbientalEmp' },
            { text: 'Proc Mitigar PoluicaoEmp', value: 'procMitigarPoluicaoEmp' },
            { text: 'Conhecimento Camaras Setoriais', value: 'conhecimentoCamarasSetoriais' },
            { text: 'Receber Info ReuCamaras', value: 'receberInfoReuCamaras' },
            { text: 'Mot Investir Incentivos Fiscais', value: 'motInvestirIncentivosFiscais' },
            { text: 'Mot Inv Mao De Obra Abundante Barata', value: 'motInvMaoDeObraAbundanteBarata' },
            { text: 'Mot Inv Infraestrutura', value: 'motInvInfraestrutura' },
            { text: 'Mot Inv Prox ConsInterno', value: 'motInvProxConsInterno' },
            { text: 'Mot Inv Prox Cons Externo', value: 'motInvProxConsExterno' },
            { text: 'Mot Inv Prox Fornecedor', value: 'motInvProxFornecedor' },
            { text: 'Mot Investir Natur Fundador', value: 'motInvestirNaturFundador' },
            { text: 'Motivo Emp Investir Outros', value: 'motivoEmpInvestirOutros' },
            { text: 'Sug Politica Desenv Industrial', value: 'sugPoliticaDesenvIndustrial' },
            { text: 'Sug Medidas Alavancar Setor', value: 'sugMedidasAlavancarSetor' },
            { text: 'Sug Observacoes', value: 'sugObservacoes' },
            { text: 'Nivel Satisfacao Fdi', value: 'nivelSatisfacaoFdi' },
            { text: 'Ano Exercicio', value: 'anoExercicio' },
            { text: 'Data Inicio Monitoramento', value: 'dataInicioMonitoramento' },
            { text: 'Data Fim Monitoramento', value: 'dataFimMonitoramento' },
            { text: 'Status Monitoramento', value: 'statusMonitoramento' },
            { text: 'Data Max Formulario', value: 'dataMaxFormulario' },
            { text: 'Denominacao Subclasse', value: 'denominacaoSubclasse' },
            { text: 'Denominacao Classe', value: 'denominacaoClasse' },
            { text: 'Denominacao Grupo', value: 'denominacaoGrupo' },
            { text: 'Denominacao Divisao', value: 'denominacaoDivisao' },
            { text: 'Denominacao Secao', value: 'denominacaoSecao' },
            { text: 'Cod Setor', value: 'codSetor' },
            { text: 'Denominacao Setor', value: 'denominacaoSetor' },
            { text: 'Cod Divisao', value: 'codDivisao' },
            { text: 'Cod Grupo', value: 'codGrupo' },
            { text: 'Cod SubClasse', value: 'codSubClasse' },
            { text: 'Cod Classe', value: 'codClasse' }
        ]
        
        return arrayValues;
    },
});