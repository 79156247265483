<template>
  <div>
    <v-app-bar
      color = "#009b06"
      height= "80px"
      dark
      overlap
      absolute
    >
      <v-toolbar-title class="title">Secretaria de Desenvolvimento Econômico do Estado do Ceará</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
        <v-badge
          color="red"
          content="1"
        >
        </v-badge>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
    name: 'header'
}
</script>

<style scoped>
    .title {
        margin-left: 80px
    }
</style>