export const CreateObjHeaderQuestionario = ( object ) => ({
    createObjtHeaderQuestionario() {
        
        let arrayValues = [
            { text: 'Empresa', value: 'empresa' },
            { text: 'Cnpj', value: 'cnpj' },
            { text: 'Tipo Pleito', value: 'tipoPleito' },
            { text: 'Data Criação', value: 'dataCriacao' },
            { text: 'Quão efetivo é o atendimento da equipe?', value: 'quao_efetivo_atendimento_equipe' },
            { text: 'Quais as recomendações?(Equipe)', value: 'quais_recomendacoes' },
            { text: 'Quão amigável a interface do sistema?', value: 'quao_amigavel_interface_sistema' },
            { text: 'Quão bem sucedido é em realizar a função?', value: 'quao_bemsucedido_realizar_funcao_propoe' },
            { text: 'Forma geral, quão satisfeito com o sistema?', value: 'forma_geral_quao_satisfetio_sistema' },
            { text: 'Quais Recomendações? (Sistema)', value: 'quais_recomendacoes' },
            { text: 'Forma geral, quão satisfeito com o Pleito?', value: 'forma_geral_quao_satisfetio_sistema' },
            { text: 'Quais Recomendações? (Pleito)', value: 'quais_recomendacoes' },
        ]
        
        return arrayValues;
    },
});