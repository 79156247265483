<template>
  <div>
    <v-card
    class="cardAditivos"
    raised
    >
    <v-list shapped>
      <v-subheader class="titleCardAditivos">Aditivos pendentes ao banco</v-subheader>
      <v-divider></v-divider>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-4"></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
          <div>Atualizado há 3 minutos atrás.</div>
        </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
  </div>
</template>

<script>
export default {
  name: 'cardAditivos',
  data: () => ({
    items: [
      { text: 'Mudança Razão social - METALVIL', icon: 'mdi-clock' },
      { text: 'Prorrogação Beneficio - VICUNHA', icon: 'mdi-clock' },
      { text: 'Alteração beneficio - 3D PUFF', icon: 'mdi-clock' },
    ],
  }),
}
</script>

<style scoped>
  .cardAditivos {
    position: relative;
    max-width: 400px;
    height: auto;
    left: 500px;
    bottom: 190px;
  }
  .titleCardAditivos {
    font-size: 19px;
    font-weight: 800;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .10s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
  }
</style>