import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/pages/Login.vue';
import Home from '@/pages/Home.vue';
import Reports from '@/pages/Reports.vue';
import Templates from '@/pages/Templates.vue';
import store from '@/stores/auth.js';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/home')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports',
    children: [
      {name: 'Pauta Reunião', path: '/reports/pautaReuniao', component: Reports},
      {name: 'Protocolos Aprovados', path: '/reports/protocoloAprovados', component: Reports},
      {name: 'Documentos Aptos Reunião', path: '/reports/documentosAptosReuniao', component: Reports},
      {name: 'Monitoramento Empresas', path: '/reports/monitoramentoEmpresas', component: Reports},
      {name: 'Empresas Ativas', path: '/reports/empresasAtivas', component: Reports},
      {name: 'Benefícios Aprovados', path: '/reports/resolucoesBeneficios', component: Reports},
      {name: 'Questionários', path: '/reports/questionarios', component: Reports}
    ],
    name: 'reports',
    component: Reports,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/templates',
    children: [
      {name: 'Pautas Reuniões', path: '/templates/pautaReuniao', component: Templates},
    ],
    name: 'templates',
    component: Templates,
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
