import ReportsRepository from './ReportsRepository.js';
import ReuniaoRepository from './ReuniaoRepository.js';
import MonitoramentoRepository from './MonitoramentoRepository';
import EmpresaRepository from './EmpresaRepository';
import QuestionarioRepository from './QuestionarioRepository';

const repositories = {
    reports: ReportsRepository,
    reuniao: ReuniaoRepository,
    monitoramento: MonitoramentoRepository,
    empresa: EmpresaRepository,
    questionarios: QuestionarioRepository
};

export default {
    get: name => repositories[name]
};