import axios from '@/plugins/axios.js';
const resource = 'relatorio/monitoramento';

export default {
    recuperarTodosMonitoramentos() {
        return axios.get(`${resource}/recuperaTodosMonitoramentos`);
    },
    gerarMonitoramentoPlanilhao() {
        return axios.get(`${resource}/recuperarMonitoramentoPlanilhao`);
    },
    gerarRelMonitoramentoPlanilhaoFiltrado(payload) {
        return axios.post(`${resource}/recuperaRelMonitoramentoPlanilhaoFiltrado`, payload);
    }
};