import axios from '@/plugins/axios.js';
import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        token: localStorage.getItem('user-token') || '',
        status: '',
        code: '',
        user: JSON.parse(localStorage.getItem('user')) || '',
    },

    getters: {
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        authCode: state => state.code,
    },

    actions: {
        ['AUTH_REQUEST']: ({commit, dispatch}, user) => {
            return new Promise((resolve, reject) => {
                commit('AUTH_REQUEST')
                axios.post(`auth/autenticar`, user).then(resp => {
                    const token = `Bearer ${resp.data.data.tokenAutenticacao.tokenAcesso}`;
                    const usu = resp.data.data.dadosUsuario;
                    localStorage.setItem('user-token', token)
                    localStorage.setItem('user', JSON.stringify(usu))
                    axios.defaults.headers.common['Authorization'] = token
                    commit('AUTH_SUCCESS', token)
                    commit('AUTH_USER', usu);
                    resolve(resp)
                })
                .catch(err => {
                    commit('AUTH_ERROR', err)
                    localStorage.removeItem('user-token')
                    reject(errors[err.response.status])
                });
            });
        } ,
        ['AUTH_LOGOUT']: ({commit, dispatch}) => {
            return new Promise((resolve, reject) => {
                commit('AUTH_LOGOUT')
                localStorage.removeItem('user-token')
                localStorage.removeItem('user')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },

    mutations: {
        ['AUTH_REQUEST']: (state) => {
          state.status = 'loading'
        },
        ['AUTH_SUCCESS']: (state, token) => {
          state.status = 'success'
          state.token = token
        },
        ['AUTH_USER']: (state, user) => {
            state.user = user
        },
        ['AUTH_ERROR']: (state, error) => {
          state.status = 'error'
          state.code = error.response.status
        },
        ['AUTH_LOGOUT']: (state) => {
            state.status = 'logout'
            state.token = ''
        },
    }
});

const errors = {
    401: {
        message: 'Credenciais inválidas'
    },
    500: {
        message: 'Servidores em manutenção'
    }
}

export default store;