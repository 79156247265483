<template>
  <div>
    <v-card
    class="filters"
    raised
    
  >
    <v-list shapped>
      <v-subheader class="titleFilters">Filtros Templates</v-subheader>
      <v-divider class="mx-4"></v-divider> 
    <v-form>
        <v-container fluid>
            <v-row>
                <v-col v-if="this.typeReports == 'Pautas Reuniões'" cols="12" sm="2">
                    <v-select
                        v-model="data.tipoReuniao"
                        :items="itemsTipoReuniao"
                        label="Tipo de Reunião">
                    </v-select>
                </v-col>

                <v-col v-if="this.typeReports == 'Pautas Reuniões'" cols="12" sm="2">
                    <v-select
                        v-model="data.dataInicialReuniao"
                        :items="itemsData"
                        label="Data da Reunião (Reuniões Abertas)"
                        @input="onChange"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-btn v-on:click="clear" class='white--text' color="#009b06"><span>Limpar</span></v-btn>
                    <v-btn :loading="loadBtnSend" v-on:click="sendFilters" class='white--text buttons' color="#009b06"><span>Gerar template</span></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
    </v-list>
  </v-card>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask';
import Repository from '@/repositories/RepositoryFactory.js';
import { CreateObjectFilters } from '@/factory/CreateObjFilters.js';
import { CreateObjHeaderToExport } from '@/factory/CreateObjHeaderToExport.js';
import { CreateObjHeaderEmpresasAtivas } from '@/factory/CreateObjHeaderEmpresasAtivas.js';
import { CreateObjHeaderQuestionario } from '@/factory/CreateObjHeaderQuestionario.js';
const ReuniaoRepository = Repository.get("reuniao");
import JSZip from "jszip";
import FileSaver from "file-saver";

export default {
  name: 'filters',
  created() {
      this.fetchDataFilters();
  },
  mounted() {
    this.$root.$on('filters', (typeReports) => {
        console.log(typeReports);
        this.fetchDataFilters(typeReports);
    });
  },
  data () {
    return {
        data: {dataInicialReuniao: 'Todas', cnpj: '', tipoPleito: 'Todos', tipoReuniao: 'Todas', programa: 'Todos', numeroProcesso: '', anoReuniao: '', dataReuniaoCedin: ''},
        itemsTipoReuniao: ['TECNICA', 'CONDEC'],
        itemProgramas: ['Todos', 'PROVIN', 'PCDM', 'FDCV', 'PIER'],
        itemTipoPleito: ['Todos', 'Pareceres de Visita', 'Pendências - Formulários', 'Suspensão e retomada de termos'],
        status: ['APROVADO', 'CRIADO', 'PARECER_TECNICO_FINALIZADO', 'CONCESSAO_DE_VISTA_TECNICA'],
        itemsData: [],
        itemsAnoReuniao: [],
        itemsDataAnoReuniao: [],
        result: [],
        rules: {
            valid: value => ( this.validCnpj(value) ) || 'Cnpj Inválido'
        },
        disabledSend: false,
        date: new Date().toISOString().substr(0, 10),
        dataInicial: null,
        dataFinal: null,
        loadBtnSend: false,
    }
  },
  props: {
      getDataFilters: '',
      typeReports: '',
  },
  components: {
    
  },
  directives: {mask},
  computed: {
    computedDateFormatted (value) {
        return this.formatDate(value)
    },
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  methods: {
    fetchDataFilters(typeReports) {
        this.disabledSend = false;
        (typeReports) ? this.typeReports = typeReports : this.typeReports;
        if (this.typeReports == 'Pautas Reuniões') {
            ReuniaoRepository.getCreateds("CRIADA").then(res => {
                if (!res.data['data'].length) { 
                    this.$emit('getDataFilters', 'Não existem reuniões abertas para consulta.');
                    this.disabledSend = true;
                }
                res.data['data'].forEach(objReu => {
                    this.itemsData.push(objReu.dataReuniao)
                    this.result.push(objReu);
                    if (this.typeReports == 'Pautas Reuniões') this.data.tipoReuniao = objReu.tipoReuniao;
                    this.data.dataInicialReuniao = objReu.dataReuniao
                });
            });
            ReuniaoRepository.getPleitos().then(res => {
                res.data['data'].forEach(val => {
                    if (val.tipoDocumento) {
                        if (!val.motivoPleito) {
                            this.itemTipoPleito.push(`${val.tipoDocumento}`);
                        } else if (val.tipoDocumento == 'Protocolo') {
                            this.itemTipoPleito.push(`${val.motivoPleito}`);
                        } else {
                            this.itemTipoPleito.push(`${val.tipoDocumento} - ${val.motivoPleito}`);
                        }
                    }
                    
                    this.result.push(val);
                });
            });
        }
    },
    formatDate (date) {
        if (!date) return null;

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
    },
    parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    },
    sendFilters() {
        let filterSend = {};
        for (var prop in this.data) {
            if (!this.data.hasOwnProperty(prop)) continue;
            if (this.data[prop]) {
                if (this.data[prop] !== "Todas" && this.data[prop] !== "Todos") {
                    filterSend[prop] = this.data[prop]
                }
            }
        }
        if (filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Aditivo") !== -1) {
            filterSend['motivoPleito'] =  filterSend.tipoDocumento.substr(10,filterSend.tipoDocumento.length);
            delete filterSend['tipoDocumento'];
        } else if (filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Pedido máquina") !== -1 || filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Pedido de importação de matéria prima") !== -1 || filterSend.tipoDocumento && filterSend.tipoDocumento.indexOf("Requerimento de Resolução") !== -1) {
            filterSend['motivoPleito'] = filterSend.tipoDocumento;
            delete filterSend['tipoDocumento'];
        }  
        this.generateTemplatePautaReuniao(filterSend);
    },
    generateTemplatePautaReuniao(payload) {
      const zip = new JSZip();
      this.loadBtnSend = true;
      if (payload.tipoReuniao === 'CONDEC') {
        payload.tipoReuniao = 'CEDIN'
      }
      ReuniaoRepository.geraRelatorio(payload).then(response => {
        console.log(response['data'].data)
        let obj = response['data'].data;
        for (var prop in obj) {
            if (obj[prop].length) {
                let arrayGrouped = this.groupArrayData(obj[prop])
                this.generateByTypeDocument(prop, zip, arrayGrouped);
            }
        }
      });
    },
    groupArrayData(array) {
        const result = [...array.reduce((r, o) => {
            const key = `${o.razaoSocial}-${o.motivoPleito}`;
            
            const item = r.get(key) || Object.assign({}, o, {
                numeroProcesso: ''
            });

            item.numeroProcesso += ` ${o.numeroProcesso}`;

            return r.set(key, item);
            }, 
        new Map).values()];
        return result;
    },
    generateXlsxFromApi(zip, data, lengthFiles, nameArq, lengthType, empName, typeProtocolo) {
        empName = empName.replace("/", "");
        if (typeProtocolo == 'Requerimento de Resolução' || typeProtocolo == 'Pedido máquina') {
            zip.remove("Resolução de Novos Benefícios")
            zip.folder("Requerimentos Máquinas e Equipamentos").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });   
        } else if (typeProtocolo == 'Pedido de importação de matéria prima') {
            zip.folder("Requerimentos Matéria Prima").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });   
        } else if (nameArq == 'Aditivo' && !zip.folder("Aditivos Contrato/Termo de Acordos").length) {
            zip.folder("Aditivos Contrato/Termo de Acordos").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
        }  else if (nameArq == 'Projeto') {
            zip.folder("Resolução de Novos Benefícios").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
            zip.remove("Aditivos Contrato");
        } else if (typeProtocolo == 'Pedido de prorrogação de benefício') {
            zip.folder("Prorrogação de Benefício").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
        }  else if (typeProtocolo == 'Pedido_RevBeneficio') {
            zip.folder("Revisão de Benefício").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
        }  else if (typeProtocolo == 'Pedido_RevBeneficio') {
            zip.folder("Revisão de Benefício").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
        }  else if (typeProtocolo == 'Protocolo') {
            zip.folder("Protocolos de Intenções").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
        }  else if (typeProtocolo == 'Consulta/Solicitação') {
            zip.folder("Pauta Extra").file(`Template ${empName} ${lengthFiles}.xlsx`, data, {binary: true });
        }
        
        if (lengthFiles == lengthType) {
            zip.generateAsync({ type: "blob" }).then(content => {
                (nameArq == 'Protocolo') ? nameArq = 'Requerimentos' : nameArq;
                FileSaver.saveAs(content, `Templates ${nameArq} Reuniões.zip`);
            });          
            this.loadBtnSend = false;   
        }
    },
    generateByTypeDocument(prop, zip, arrayGrouped) {
        if (prop == 'protocolos') {
            let lengthObj = arrayGrouped.length;
            let i = 1;
            this.requestGenerateXls(arrayGrouped, zip, "Protocolo", lengthObj, i);
        }
        if (prop == 'aditivos') {
            let lengthObj = arrayGrouped.length;
            let i = 1;
            this.requestGenerateXls(arrayGrouped, zip, "Aditivo", lengthObj, i);
        }
        if (prop == 'projetos') {
            let lengthObj = arrayGrouped.length;
            let i = 1;
            this.requestGenerateXls(arrayGrouped, zip, "Projeto", lengthObj, i);
        }
    },
    requestGenerateXls(arrayGrouped, zip, typeDocument, lengthObj, count) {
        arrayGrouped.forEach(async x => {
            x['tipoDocumento'] = typeDocument;
            if (typeDocument == 'Protocolo') {
                x['tipoDocumento'] = x['tipo_protocolo'];
            }
            await ReuniaoRepository.generateTemplateDocumentosAptos(x).then(resData => {
                this.generateXlsxFromApi(zip, resData.data, count++, typeDocument, lengthObj, x['razaoSocial'], x['tipo_protocolo']);
            });
        });
    },
    clear() {
        this.data.cnpj = '';
        this.data.tipoDocumento = '';
        this.data.tipoReuniao = 'TECNICA';
        this.data.programa = '';
        this.data.numeroProcesso = '';
    },
    onChange(event) {
        
    },
    validCnpj(cnpj) {
        if (cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g, '');

            if (cnpj == '') {
                this.disabledSend = false;
                return true;
            } 

            if (cnpj.length != 14) {
                this.disabledSend = true;
                return false;
            }
                

        
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999") {
                    this.disabledSend = true;
                    return false;
                }
                
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0, tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) return false;
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1)) {
                this.disabledSend = true;
                return false;
            }

            this.disabledSend = false;
            return true;
        }
    }
  }
}
</script>

<style scoped>
  .filters {
    position: relative;
    margin-right: 7%;
    left: 100px;
    top: 60px;
    height: 330px;
    margin-bottom: 0%;
  }
  .titleFilters {
    font-size: 19px;
    font-weight: 800;
  }
  .buttons {
    margin-left: 3%;
  }
  .btnExport {
    position: relative;
    left: 97px;
    bottom: 43px;
  }
  .btnExportEmpresasAtivas {
    position: relative;
    right: 5px;
    bottom: 5px;
  }
</style>